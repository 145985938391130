import { mdiAlphaABox, mdiAlphaPBox, mdiClockTimeEightOutline, mdiTimelapse } from '@mdi/js'

export default [
  {
    subheader: 'Product Management',
  },
  {
    title: 'Product',
    icon: mdiAlphaPBox,
    children: [
      {
        title: 'Product List',
        to: 'product-list',
      },
      {
        title: 'Bulk Upload',
        to: 'spreadsheet-uploader',
      },
      {
        title: 'SKU List',
        to: 'sku-list',
      },
      {
        title: 'Voucher List',
        to: 'voucher-list',
      },

      {
        title: 'Prouct Review List',
        to: 'product-review-list',
      },
      {
        title: 'Product Template List',
        to: 'product-template-list',
      },

      {
        title: 'Voucher Template List',
        to: 'voucher-template-list',
      },
      {
        title: 'Attribute Type List',
        to: 'attribute-type-list',
      },

      {
        title: 'Attribute Value List',
        to: 'attribute-value-list',
      },

      {
        title: 'Brand List',
        to: 'brand-list',
      },

      {
        title: 'Participant Rule List',
        to: 'participant-rule-list',
      },

      {
        title: 'Quota Rule List',
        to: 'quota-rule-list',
      },
    ],
  },
  {
    title: 'Appointment',
    icon: mdiAlphaABox,
    children: [
      {
        title: 'Appointments',
        to: 'appointment-list',
        icon: mdiClockTimeEightOutline,
      },
      {
        title: 'History',
        to: 'appointment-history',
      },
    ],
  },
  {
    title: 'Activity',
    icon: mdiTimelapse,
    children: [
      {
        title: 'Activity',
        to: 'activity-list',
      },
      {
        title: 'Activity Rule',
        to: 'activity-rule-list',
      },
      {
        title: 'Rule Template',
        to: 'activity-rule-template-list',
      },
    ],
  },
]
