import { mdiAlphaCBox, mdiAlphaSBox } from '@mdi/js'

export default [
  {
    subheader: 'Common Management',
  },
  {
    title: 'Content Relations',
    icon: mdiAlphaCBox,
    children: [
      {
        title: 'Category',
        to: 'category-list',
      },
      {
        title: 'Tag',
        to: 'tag-list',
      },
      {
        title: 'Content Approval',
        to: 'content-request-list',
      },
      {
        title: 'Ability',
        to: 'ability-list',
      },
      {
        title: 'Option Group',
        to: 'option-group-list',
      },
    ],
  },
  {
    title: 'General Model',
    icon: mdiAlphaSBox,
    children: [
      {
        title: 'Model Token',
        to: 'model-token-list',
      },
      {
        title: 'Model Schedule',
        to: 'model-schedule-list',
      },
    ],
  },
]
