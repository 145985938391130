export default [
  {
    path: '/product/product-list',
    name: 'product-list',
    component: () => import('@/modules/product/views/product-list/ProductList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/view/:id',
    name: 'product-view',
    component: () => import('@/modules/product/views/product-view/ProductView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/sku/sku-list',
    name: 'sku-list',
    component: () => import('@/modules/product/views/sku-list/SkuList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/voucher-list',
    name: 'voucher-list',
    component: () => import('@/modules/product/views/voucher-list/VoucherList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/bulk-upload/spreadsheet-uploader',
    name: 'spreadsheet-uploader',
    component: () => import('@/modules/product/views/bulk-upload/SpreadsheetUploader.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/attribute-type-list',
    name: 'attribute-type-list',
    component: () => import('@/modules/product/views/attribute-type-list/AttributeTypeList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/attribute-value-list',
    name: 'attribute-value-list',
    component: () => import('@/modules/product/views/attribute-value-list/AttributeValueList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/brand-list',
    name: 'brand-list',
    component: () => import('@/modules/product/views/brand-list/BrandList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/product-template-list',
    name: 'product-template-list',
    component: () => import('@/modules/product/views/product-template/ProductTemplateList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/voucher-template-list',
    name: 'voucher-template-list',
    component: () => import('@/modules/product/views/voucher-template-list/VoucherTemplateList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/participant-rule-list',
    name: 'participant-rule-list',
    component: () => import('@/modules/product/views/participant-rule-list/ParticipantRuleList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/quota-rule-list',
    name: 'quota-rule-list',
    component: () => import('@/modules/product/views/quota-rule/QuotaRuleList.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/product/review-list',
    name: 'product-review-list',
    component: () => import('@/modules/product/views/ProductReviewList.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Hidden Product, SKU manage page ——————————————————
  //
  {
    path: '/product/product-create',
    name: 'product-create-resource',
    component: () => import('@/modules/product/views/product-resource/ProductCreate.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/product/product-update',
    name: 'product-update-resource',
    component: () => import('@/modules/product/views/product-resource/ProductUpdate.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/product/sku-create',
    name: 'sku-create-resource',
    component: () => import('@/modules/product/views/sku-resource/SkuCreate.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/product/sku-update',
    name: 'sku-update-resource',
    component: () => import('@/modules/product/views/sku-resource/SkuUpdate.vue'),
    meta: {
      layout: 'content',
    },
  },
]
