import { mdiEarth } from '@mdi/js'

export default [
  {
    subheader: 'Country Management',
  },
  {
    title: 'Countries',
    icon: mdiEarth,
    to: 'country-list',
  },
]
