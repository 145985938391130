import { mdiStoreMinusOutline, mdiViewList } from '@mdi/js'

export default [
  {
    subheader: 'Merchant Management',
  },
  {
    title: 'Merchants',
    icon: mdiStoreMinusOutline,
    to: 'list-merchants',
  },
  {
    title: 'Notification Templates',
    icon: mdiViewList,
    to: 'merchant-template-list',
  },
]
