import channel from '@/modules/channel/menu/vertical'
import common from '@/modules/common/menu/vertical'
import country from '@/modules/country/menu/vertical'
import dashboard from '@/modules/dashboard/menu/vertical'
import distributor from '@/modules/distributor/menu/vertical'
import merchant from '@/modules/merchant/menu/vertical'
import order from '@/modules/order/menu/vertical'
import product from '@/modules/product/menu/vertical'
import reporting from '@/modules/reporting/menu/vertical'
import user from '@/modules/user/menu/vertical'
import utility from '@/modules/utility/menu/vertical'

export default [...dashboard, ...order, ...product, ...merchant, ...channel, ...distributor, ...user, ...reporting, ...country, ...common, ...utility]
